import { Tag } from "@lezer/highlight";
import { Decoration, EditorView, ViewPlugin, WidgetType } from "@codemirror/view";
import { NodeProp } from "@lezer/common";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
const InlineFootnote = new Tag();
const InlineFootnoteMark = new Tag();
const InlineFootnoteInfo = new NodeProp({ perNode: true });
class BlockQuoteBorderWidget extends WidgetType {
    constructor(info, setCursor) {
        super();
        this.info = info;
        this.setCursor = setCursor;
    }
    // noinspection JSUnusedGlobalSymbols
    toDOM() {
        const dom = document.createElement('span');
        dom.innerHTML = "mode_comment";
        dom.title = this.info;
        dom.classList.add("material-icons");
        dom.classList.add("rotated");
        dom.classList.add("footnote-mark");
        dom.onclick = ev => {
            ev.preventDefault();
            this.setCursor(ev);
        };
        return dom;
    }
}
let inlineFootnote = () => {
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.headings(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.headings(update.view);
            }
            headings(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (node) => {
                            var _a;
                            if (node.name === "InlineFootnote") {
                                let info = (_a = node === null || node === void 0 ? void 0 : node.tree) === null || _a === void 0 ? void 0 : _a.prop(InlineFootnoteInfo);
                                const placeCursor = {
                                    selection: {
                                        anchor: node.from + 2,
                                        head: node.from + 2,
                                    }
                                };
                                let deco = Decoration.widget({
                                    widget: new BlockQuoteBorderWidget(info, () => view.dispatch(placeCursor))
                                });
                                items.push(new DecorationHolder(deco, node.from, node.to, true));
                                items.push(new DecorationHolder(Decoration.mark({ class: "cm-mark" }), node.from, node.to));
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets, true);
            }
        }, {
            decorations: v => v.decorations
        }),
        EditorView.baseTheme({
            ".footnote-mark": {
                color: "var(--text-link-color, blue)"
            },
            ".footnote-mark:hover": {
                color: "var(--text-link-hover-color, darkblue)"
            }
        })];
};
const regExp = /(?<mark1>\^\[)(?<info>[^\]]*)(?<mark2>])/gm;
export let inlineFootnoteSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "InlineFootnote", style: InlineFootnote },
                { name: "InlineFootnoteMark", style: InlineFootnoteMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    //console.log(match);
                    return context.createTree({
                        name: "InlineFootnote",
                        children: [
                            context.createTree({
                                name: "InlineFootnoteMark",
                                range: context.buildRange({
                                    from: match.index,
                                    length: match.groups.mark1.length
                                })
                            }),
                            context.createTree({
                                name: "InlineFootnoteMark",
                                range: context.buildRange({
                                    from: match.index,
                                    length: match[0].length - match.groups.mark1.length
                                })
                            })
                        ],
                        range: context.buildRange({
                            from: match.index, length: match[0].length
                        }),
                        props: [[InlineFootnoteInfo, match.groups.info]]
                    });
                });
            }
        }],
    language: [
        inlineFootnote()
    ]
};
