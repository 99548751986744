import { syntaxTree } from "@codemirror/language";
import { undo, redo } from "./history";
function notImplemented(functionName) {
    console.error(`${functionName} not implemented`);
    alert(`${functionName} not implemented`);
}
export class EventAction {
    constructor(view) {
        this.view = view;
    }
    removeMarks() {
        let range = this.getRange();
        let changes = new Array();
        syntaxTree(this.view.state).iterate({
            from: range.from,
            to: range.to,
            enter: ({ type, from, to }) => {
                if (!type.name.match("Mark"))
                    return;
                let char = this.view.state.sliceDoc(to, to + 1);
                changes.push({
                    from: from,
                    to: to + (char === " " ? 1 : 0)
                });
            }
        });
        this.view.dispatch({
            changes: changes
        });
    }
    setHeader(headerLevel) {
        this.appendToLines("#".repeat(headerLevel) + " ");
    }
    getAffectedLines() {
        const lines = new Array();
        for (let lineNumber = this.view.state.doc.lineAt(this.view.state.selection.main.from).number; lineNumber <= this.view.state.doc.lineAt(this.view.state.selection.main.to).number; lineNumber++) {
            lines.push(this.view.state.doc.line(lineNumber));
        }
        return lines;
    }
    getRange() {
        return this.view.state.selection.main;
    }
    addStrongEmphasis() {
        this.wrapRange({ start: "**", end: "**" });
    }
    addEmphasis() {
        this.wrapRange({ start: "*", end: "*" });
    }
    addHighlighting() {
        this.wrapRange({ start: "[", end: "]{.mark}" });
    }
    addStrikeout() {
        this.wrapRange({ start: "~~", end: "~~" });
    }
    addSuperscripts() {
        this.wrapRange({ start: "^", end: "^" });
    }
    addSubscripts() {
        this.wrapRange({ start: "~", end: "~" });
    }
    addUnderline() {
        this.wrapRange({ start: "[", end: "]{.underline}" });
    }
    addSmallCaps() {
        this.wrapRange({ start: "[", end: "]{.smallcaps}" });
    }
    addVerbatim() {
        //todo there is also `` ` ``
        this.wrapRange({ start: "`", end: "`" });
    }
    addUnorderedList() {
        this.appendToLines("- ");
    }
    addCheckedList() {
        this.appendToLines("- [ ] ");
    }
    addOrderedList() {
        this.appendToLines("#. ");
    }
    addNumberedExampleLists() {
        this.appendToLines("(@) ");
    }
    addDefinitionLists() {
        const lastLine = this.view.state.doc.lineAt(this.view.state.selection.main.to);
        const string = "\nterm\n\t~Definition\n";
        const newPosition = lastLine.to + 1;
        this.view.dispatch({
            changes: {
                from: lastLine.to,
                to: lastLine.to,
                insert: string
            },
            selection: {
                anchor: newPosition,
                head: newPosition + 4,
            }
        });
    }
    addLink() {
        notImplemented("addLink");
    }
    addInternalLink() {
        notImplemented("addInternalLink");
    }
    addReferenceLink() {
        notImplemented("addReferenceLink");
    }
    addImage() {
        notImplemented("addImage");
    }
    addReferenceImage() {
        notImplemented("addReferenceImage");
    }
    addHorizontalRule() {
        const lastLine = this.view.state.doc.lineAt(this.view.state.selection.main.to);
        const string = "\n\n***\n\n";
        this.view.dispatch({
            changes: {
                from: lastLine.to,
                to: lastLine.to,
                insert: string
            },
        });
    }
    addBlockQuote() {
        notImplemented("addBlockQuote");
    }
    addCodeBlock() {
        notImplemented("addCodeBlock");
    }
    addCodeInline() {
        notImplemented("addCodeInline");
    }
    addFencedCodeBlock() {
        notImplemented("addFencedCodeBlock");
    }
    addTables() {
        notImplemented("addTables");
    }
    addMetadataBlocks() {
        notImplemented("addMetadataBlocks");
    }
    addYamlMetadataBlock() {
        notImplemented("addYamlMetadataBlock");
    }
    addFencedDivs() {
        notImplemented("addFencedDivs");
    }
    addBracketedSpans() {
        notImplemented("addBracketedSpans");
    }
    addFootnotes() {
        notImplemented("addFootnotes");
    }
    addInlineNotes() {
        let newPosition = this.view.state.selection.main.to + 2;
        this.view.dispatch({
            changes: [
                {
                    from: this.view.state.selection.main.to,
                    to: this.view.state.selection.main.to,
                    insert: "^[]"
                }
            ],
            selection: {
                anchor: newPosition,
                head: newPosition,
            }
        });
    }
    increaseIndent() {
        this.appendToLines("\t");
    }
    decreaseIndent() {
        const lines = this.getAffectedLines();
        this.view.dispatch({
            changes: lines.filter(value => this.view.state.sliceDoc(value.from, value.from + 1).match(/^[\t ]/gm))
                .map(value => ({
                from: value.from,
                to: value.from + 1
            }))
        });
    }
    wrapRange({ start, end }) {
        const range = this.getRange();
        let selection = undefined;
        if (range.from === range.to) {
            let newPosition = range.from + start.length;
            selection = {
                anchor: newPosition,
                head: newPosition,
            };
        }
        this.view.dispatch({
            changes: [
                {
                    from: range.from,
                    to: range.from,
                    insert: start
                },
                {
                    from: range.to,
                    to: range.to,
                    insert: end
                }
            ],
            selection
        });
    }
    appendToLines(string) {
        const lines = this.getAffectedLines();
        let selection = undefined;
        if (lines.length == 1 && lines[0].from === lines[0].to) {
            let newPosition = lines[0].from + string.length;
            selection = {
                anchor: newPosition,
                head: newPosition,
            };
        }
        this.view.dispatch({
            changes: lines.map(value => ({
                from: value.from,
                to: value.from,
                insert: string
            })),
            selection
        });
    }
    addComment() {
        this.wrapRange({ start: "<!-- ", end: " -->" });
    }
    insertTab() {
        let main = this.view.state.selection.main;
        const string = "\t";
        this.view.dispatch({
            changes: {
                from: main.from,
                to: main.to,
                insert: string
            },
            selection: {
                anchor: main.from + string.length,
                head: main.from + string.length,
            }
        });
    }
    undo() {
        undo(this.view);
    }
    redo() {
        redo(this.view);
    }
}
