import { Tag } from "@lezer/highlight";
import { Decoration, EditorView, ViewPlugin, WidgetType } from "@codemirror/view";
import { NodeProp } from "@lezer/common";
import DecorationHolder from "./util/DecorationHolder";
import { syntaxTree } from "@codemirror/language";
import { TodoMark } from "./todoListSimpleLanguage";
const Example = new Tag();
const ExampleMark = new Tag();
const ExampleLink = new Tag();
const ExampleId = new NodeProp({ perNode: true });
let map = new Map();
class LinkWidget extends WidgetType {
    constructor(id, setCursor) {
        super();
        this.id = id;
        this.setCursor = setCursor;
    }
    // noinspection JSUnusedGlobalSymbols
    toDOM() {
        const dom = document.createElement('a');
        dom.innerHTML = "&raquo;";
        dom.href = "#";
        dom.title = "got to " + this.id;
        dom.onclick = ev => {
            console.log("test");
            ev.preventDefault();
            this.setCursor(ev);
        };
        return dom;
    }
}
let example = () => {
    map = new Map();
    return [ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.example(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.example(update.view);
            }
            example(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (headerNode) => {
                            //console.log(headerNode.name, headerNode.from, headerNode.to);// use this to print out tags.
                            if (headerNode.name === "Example") {
                                // @ts-ignore
                                syntaxTree(view.state).iterate({
                                    from: headerNode.from,
                                    to: headerNode.to,
                                    enter: (node) => {
                                        var _a;
                                        if (node.name === "ExampleMark") {
                                            let id = (_a = node === null || node === void 0 ? void 0 : node.tree) === null || _a === void 0 ? void 0 : _a.prop(ExampleId);
                                            items.push(new DecorationHolder(Decoration.mark({
                                                attributes: {
                                                    id: id
                                                },
                                                class: "example_mark"
                                            }), node.from, node.to));
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        }),
        ViewPlugin.fromClass(class {
            constructor(view) {
                this.decorations = this.example(view);
            }
            update(update) {
                if (update.docChanged || update.viewportChanged || update.selectionSet)
                    this.decorations = this.example(update.view);
            }
            example(view) {
                // @ts-ignore
                let items = [];
                for (let { from, to } of view.visibleRanges) {
                    syntaxTree(view.state).iterate({
                        from, to,
                        enter: (node) => {
                            var _a;
                            //console.log(headerNode.name, headerNode.from, headerNode.to);// use this to print out tags.
                            if (node.name === "ExampleLink") {
                                // @ts-ignore
                                let id = (_a = node === null || node === void 0 ? void 0 : node.tree) === null || _a === void 0 ? void 0 : _a.prop(ExampleId);
                                items.push(new DecorationHolder(Decoration.mark({
                                    class: "example_mark"
                                }), node.from, node.to));
                                if (map.has(id)) {
                                    const placeCursor = {
                                        selection: {
                                            anchor: map.get(id).from,
                                            head: map.get(id).to,
                                        },
                                        scrollIntoView: true
                                    };
                                    items.push(new DecorationHolder(Decoration.widget({
                                        widget: new LinkWidget(id, () => {
                                            view.dispatch(placeCursor);
                                        })
                                    }), node.to, node.to));
                                }
                            }
                        }
                    });
                }
                let widgets = [];
                items.sort((a, b) => a.compareTo(b))
                    .forEach(value => widgets.push(value.buildRange()));
                return Decoration.set(widgets);
            }
        }, {
            decorations: v => v.decorations
        }),
        EditorView.baseTheme({
            '.example_mark': {
                fontWeight: 'bold',
            }
        })];
};
const regExp = /^(?<example>(?<exampleMark>[^\S\n]*\(@(?<id>[\w_\-]*)\)(?<todoMark>[^\S\n]\[[ xX]])?[^\S\n])[^\n]*)$/gm;
const regExp2 = /(?<pre>[^\S\n])(?<exampleLink>\(@(?<id>[\w_\-]+)\))(?<post>\s)/gm;
export let exampleSimpleLanguage = {
    parser: [{
            defineNodes: [
                { name: "Example", style: Example },
                { name: "ExampleMark", style: ExampleMark },
                { name: "TodoMark", style: TodoMark }
            ],
            parser: (context) => {
                let matchArray = context.match(regExp);
                return matchArray.map((match) => {
                    map.set(match.groups.id, context.buildRange({ from: match.index, length: match.groups.exampleMark.length }));
                    let exampleMarkChildren = [];
                    if (match.groups.todoMark) {
                        exampleMarkChildren.push(context.createTree({
                            name: "TodoMark",
                            range: context.buildRange({
                                from: match.index + match.groups.exampleMark.length - match.groups.todoMark.length,
                                length: match.groups.todoMark.length
                            })
                        }));
                    }
                    return context.createTree({
                        name: "Example", children: [context.createTree({
                                name: "ExampleMark",
                                range: context.buildRange({
                                    from: match.index,
                                    length: match.groups.exampleMark.length
                                }),
                                children: exampleMarkChildren,
                                props: [[ExampleId, match.groups.id]]
                            })],
                        range: context.buildRange({
                            from: match.index,
                            length: match.groups.example.length
                        })
                    });
                });
            }
        }, {
            defineNodes: [
                { name: "ExampleLink", style: ExampleLink },
            ],
            parser: (context) => {
                let matchArray = context.match(regExp2);
                return matchArray.map((match) => {
                    return context.createTree({
                        name: "ExampleLink",
                        range: context.buildRange({
                            from: match.index + match.groups.pre.length, length: match.groups.exampleLink.length
                        }),
                        props: [[ExampleId, match.groups.id]]
                    });
                });
            }
        }],
    language: [
        example()
    ]
};
